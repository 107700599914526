var win = $(window);

// This function looks for sibling elements which are specified to have the same height and makes
// sure the shortest of the two is stretched to match its sibling's taller height
function initSameHeight() {
    var same = $('.eq-height');
    same.matchHeight({
        property: 'min-height'
    });
}

function initSameHeightMenu() {
    var same = $('.eq-height-menu');
    if (win.width() > 768) {
        same.matchHeight({
            property: 'min-height'
        });
    }
}

function loadVivitionPlayer() {
    // Append the script
    var src = "https://static-api.vivition.com/scripts/vivitionplayer.bundle.js"
    var script = document.createElement('script');
    script.setAttribute('src', src);
    $( ".vivition-player" ).append(script);

    waitForVivition();

}

function waitForVivition() {
    if(typeof Vivition !== 'undefined') {

        var oVivitionLoader = document.querySelector('.vivition-loader');

        vivitionToggleLoader(oVivitionLoader);
        hasVivitionFullyLoaded = false;

        Vivition.API.AddEventListener({
            event: 'readystate',
            callback: function(e) {
                // When Vivition is loaded 100%
                if(e.total === 100) {

                    // Photoseriestypes returns array with ints which corrospond with phototypes available
                    // 1 = DOORS_CLOSED_LIGHTS_OFF / DEFAULT
                    // 2 = INTERIOR
                    // 3 = DOORS_OPENED_LIGHTS_OFF
                    // 4 = DOORS_CLOSED_LIGHTS_ON
                    // 5 = DOORS_OPENED_LIGHTS_ON

                    var availablePhotoTypes = e.photoseriestypes;
                    var oUIContainer = document.querySelector('.js-vivition-ui');
                    var oZoomInBtn = oUIContainer.querySelector('.js-vivition-zoom-in');
                    var oZoomOutBtn = oUIContainer.querySelector('.js-vivition-zoom-out');
                    var oSwitchInteriorBtn = oUIContainer.querySelector('.js-vivition-switch');

                    // Check if Interior photos are availeble
                    if(availablePhotoTypes.indexOf(2) !== -1) {
                        oSwitchInteriorBtn.addEventListener('click', function(e) {
                            var switchType = parseInt(e.currentTarget.dataset['type'], 10);
                            vivitionToggleInterior(switchType, e.currentTarget);

                        });

                        oSwitchInteriorBtn.classList.add('active');
                    } else {
                        if(oSwitchInteriorBtn.classList.contains('active')) {
                            oSwitchInteriorBtn.classList.remove('active');
                        }
                    }

                    oZoomInBtn.classList.add('active');
                    oZoomOutBtn.classList.add('active');

                    oZoomInBtn.addEventListener('click', vivitionPlayerZoomIn);
                    oZoomOutBtn.addEventListener('click', vivitionPlayerZoomOut);

                    if(!hasVivitionFullyLoaded) {
                        Vivition.API.SetPlayState({
                            state: Vivition.API.Constants.PlayStates.PLAYING
                        });

                        vivitionToggleLoader(oVivitionLoader);
                    }

                    if(!hasVivitionFullyLoaded) {
                        hasVivitionFullyLoaded = true;
                    }
                }
            }
        });
    } else {
        setTimeout(waitForVivition, 10);
    }
}

function vivitionToggleLoader(loader) {
    if(loader.classList.contains('active')) {
        loader.classList.remove('active');
    } else {
        loader.classList.add('active');
    }
}

function vivitionToggleInterior(type, btn) {
    if(typeof Vivition !== 'undefined')
    {
        var photoSeriesType = (type === 1 ? Vivition.API.Constants.PhotoSeriesTypes.DEFAULT : Vivition.API.Constants.PhotoSeriesTypes.INTERIOR);
        var newType = type === 1 ? 2 : 1;
        Vivition.API.SetPhotoSeriesType({
            type: photoSeriesType
        });

        btn.setAttribute('data-type', newType);
    }
}

function vivitionPlayerZoomIn() {
    if(typeof Vivition !== 'undefined') {
        Vivition.API.ZoomIn();
    }
}

function vivitionPlayerZoomOut() {
    if(typeof Vivition !== 'undefined') {
        Vivition.API.ZoomOut();
    }
}
function submenuMouseover(mainnav, element) {
    element.addEventListener('mouseover', function () {
        if (window.innerWidth < 769) return;

        var current = mainnav.querySelector('.submenu-active');

        if (current) current.classList.remove('submenu-active');
        element.classList.add('submenu-active');
    });
}

function submenuMouseout(element) {
    element.addEventListener('mouseout', function () {
        if (window.innerWidth < 769) return;

        var current = mainnav.querySelector('.submenu-active');
        if (current) current.classList.remove('submenu-active');
    });
}

function submenuClick(mainnav, element) {
    element.addEventListener('click', function () {
        if (window.innerWidth < 769) {
            var current = mainnav.querySelector('.submenu-active');

            if (element.classList.contains('submenu-active')) {
                element.classList.remove('submenu-active');
            } else {
                if (current) {
                    current.classList.remove('submenu-active');
                }

                element.classList.add('submenu-active');
            }
        }
    });
}

function submenuLinkClick(element) {
    element.addEventListener('click', function (event) {
        if (window.innerWidth < 769) {
            event.preventDefault();
        }
    });
}

function mainnavSubmenus() {
    var mainnav = document.querySelector('[data-mainnav]');
    var submenuParents = document.querySelectorAll('[data-mainnav-hassubmenu]');

    for (var a = 0; a < submenuParents.length; a += 1) {
        var element = submenuParents[a];
        var link = element.querySelector('a');

        submenuMouseover(mainnav, element);
        submenuMouseout(element);
        submenuClick(mainnav, element);
        submenuLinkClick(link);
    }
}

mainnavSubmenus();
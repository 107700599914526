// Thank you with confetti
const confettiDuration = 7 * 1000;
const confettiEnd = Date.now() + confettiDuration;
const confettiColors = ['#ef7d00', '#005ca9'];

function startConfetti() {
  // Left side confetti
  confetti({
    particleCount: 9,
    angle: 60,
    spread: 150,
    origin: { x: 0 },
    gravity: 0.6,
    colors: confettiColors,
  });
  
  // Right side confetti.
  confetti({
    particleCount: 9,
    angle: 120,
    spread: 150,
    origin: { x: 1 },
    gravity: 0.6,
    colors: confettiColors,
  });

  // Keep going until we are out of time.
  if (Date.now() < confettiEnd) {
    requestAnimationFrame(startConfetti);
  }
}

// Check when to start confetti.
if (document.querySelectorAll('.js-confetti').length) { 
    startConfetti();
}
